html,
body,
#root {
  height: 100%;
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  font-family: "Averta", "Helvetica Neue", Helvetica, Arial, "Lucida Grande",
  sans-serif;
  background-color: #F5F6F7;
  color: #212F49;
  line-height: 24px;
  position: relative;
  z-index: 10001;
  overflow: hidden;
}

#root {
  overflow: scroll;
}

a {
  font-size: 14px;
  color: #00a0ff;
}

a:hover {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "Averta", "Helvetica Neue", Helvetica, Arial, "Lucida Grande",
  sans-serif;
}

h1, h2 {
  font-weight: 300;
}

h1 strong, h2 strong {
  color: #FF5253;
}

h1 {
  font-size: 44px;
  line-height: 48px;
  color: #212f49;
  margin-bottom: 24px;
  margin-top: 24px;
}

h2 {
  font-size: 24px;
  line-height: 24px;
  color: #212F49;
  margin: 0;
}

small {
  line-height: 12px;
  font-size: 12px;
}

.react-tooltip {
  z-index: 101;
}
